import "./Loader.scss";
const Loader = ({ show = true }) => {
  return (
    <div className={show ? "d-block" : "d-none"}>
      <div className="loader"></div>
      <div className="overlay"></div>
    </div>
  );
};
export default Loader;
