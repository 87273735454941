import React, { forwardRef } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import "./InputField.scss";

const InputFields = forwardRef(
  (
    {
      label,
      type,
      name,
      placeholder,
      errors,
      onChange,
      value = "",
      handleKeyDown,
      showPasswordToggle,
      onTogglePassword,
      showPassword,
      title,
      additionalClass,
      autoComplete,
      ariaLabel,
      ariaDescribedby,
    },
    ref,
  ) => {
    return (
      <>
        {label && (
          <label className="label" htmlFor={name}>
            {label}
          </label>
        )}
        <div className="inputWrapper">
          <input
            id={name}
            ref={ref} // Forwarded ref for react-hook-form
            type={type}
            name={name}
            placeholder={placeholder}
            className={`inputs ${errors ? "error-outline" : ""}`}
            onChange={onChange}
            value={value}
            onKeyDown={handleKeyDown}
            title={title}
            autoComplete={autoComplete}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
          />
          {showPasswordToggle && (
            <button
              type="button"
              onClick={onTogglePassword}
              aria-label={name}
              className="passwordToggleBtn"
            >
              {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </button>
          )}
        </div>
        {errors && (
          <p className={`error  ${additionalClass}`}>
            {errors?.message ? errors.message : `${name} is ${errors.type}`}
          </p>
        )}
      </>
    );
  },
);

// Adding display name for easier debugging and to resolve ESLint warning
InputFields.displayName = "InputFields";

export default InputFields;
