import React from "react";
import "./SkipLink.scss";

const SkipLink = () => (
  <a href="#mainContent" className="skip-link">
    Skip to main content
  </a>
);

export default SkipLink;
