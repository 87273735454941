import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  categoryList: [],
  loading: false,
  status: "",
  error: "",
};

export const getCategoryListAction = createAsyncThunk(
  "category/list",
  async () => {
    let API_URL = `${SERVER_URL.CATEGORY}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

export const saveCategoryAction = createAsyncThunk(
  "category/save",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_CATEGORY}`;

    try {
      const { data } = await fetchUtility(
        "post",
        `${API_URL}`,
        params.body,
        true,
        { successToast: true, errorToast: false },
      );
      return data; // If the request is successful, return the data
    } catch (error) {
      const errors = {
        username: error.response?.data?.username?.[0] || null,
        email: error.response?.data?.email?.[0] || null,
        password: error.response?.data?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateCategoryAction = createAsyncThunk(
  "category/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.CATEGORY}`;
      const { data } = await fetchUtility(
        "put",
        `${API_URL}${params.body.category_id}/`,
        params.body,
        true,
        { successToast: false, errorToast: false },
      );
      return data;
    } catch (error) {
      const errors = {
        username: error.response?.data?.username?.[0] || null,
        email: error.response?.data?.email?.[0] || null,
        password: error.response?.data?.password?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const deleteCategoryAction = createAsyncThunk(
  "category/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.CATEGORY}`;
    const { data } = await fetchUtility(
      "delete",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: true },
    );
    return data;
  },
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  extraReducers: (builder) => {
    //category List
    builder.addCase(getCategoryListAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoryListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.categoryList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getCategoryListAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //create new category
    builder.addCase(saveCategoryAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCategoryAction.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(saveCategoryAction.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //update category
    builder.addCase(updateCategoryAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateCategoryAction.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateCategoryAction.rejected, (state, action) => {
      state.loading = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //delete category
    builder.addCase(deleteCategoryAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCategoryAction.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteCategoryAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});
export default categorySlice.reducer;
