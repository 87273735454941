// eslint-disable-next-line no-undef
export const BASIC_API_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = {
  LOGIN: `${BASIC_API_URL}/users/login/`,
  USER_LIST: `${BASIC_API_URL}/users/list/`,
  USER_PROFILE: `${BASIC_API_URL}/users/profile/`,
  CREATE_USER: `${BASIC_API_URL}/users/register/`,
  UPDATE_USER: `${BASIC_API_URL}/users/update/`,
  DELETE_USER: `${BASIC_API_URL}/users/delete/`,
  RESET_PASSWORD: `${BASIC_API_URL}/users/change-password/`,
  MFA_STATUS: `${BASIC_API_URL}/users/mfa/status/`,
  MFA_REGISTER: `${BASIC_API_URL}/users/mfa/setup/`,
  MFA_VERIFY: `${BASIC_API_URL}/users/mfa/verify/`,
  MFA_REMOVE: `${BASIC_API_URL}/users/mfa/disable/`,
  USER_VERIFY: `${BASIC_API_URL}/users/verify-user/`,
  LOGOUT: `${BASIC_API_URL}/users/logout/`,
  UPDATE_PROFILE: `${BASIC_API_URL}/users/profile/update/`,
  REFRESH_TOKEN: `${BASIC_API_URL}/users/token/refresh/`,
  PROPERTY_LIST: `${BASIC_API_URL}/property/list/`,
  CREATE_PROPERTY: `${BASIC_API_URL}/property/create/`,
  UPDATE_PROPERTY: `${BASIC_API_URL}/property/update/`,
  DELETE_PROPERTY: `${BASIC_API_URL}/property/delete/`,
  RETRIEVE_PROPERTY: `${BASIC_API_URL}/property/view`,
  CATEGORY: `${BASIC_API_URL}/category/`,
  CREATE_CATEGORY: `${BASIC_API_URL}/category/create/`,
  VENDOR: `${BASIC_API_URL}/vendors/`,
  CREATE_VENDOR: `${BASIC_API_URL}/vendors/create/`,
};

export const role = [
  {
    id: 1,
    label: "Admin",
    value: true,
  },
  {
    id: 2,
    label: "Standard",
    value: false,
  },
];
export const status = [
  {
    id: 1,
    label: "Active",
    value: true,
  },
  {
    id: 2,
    label: "Inactive",
    value: false,
  },
];

export const statusType = [
  {
    id: 1,
    label: "Active",
    value: "Active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "Inactive",
  },
];

export const propertyTypes = [
  { label: "Commercial", value: "Commercial" },
  { label: "Residential", value: "Residential" },
];

export const propertyStatus = [
  { label: "Active", value: "Active" },
  { label: "Archived", value: "Archived" },
];

export default SERVER_URL;
