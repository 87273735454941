import { configureStore } from "@reduxjs/toolkit";
import LoginSlices from "./slices/LoginSlices";
import UserSlices from "./slices/UserSlices";
import ChangePasswordSlices from "./slices/ChangePasswordSlices";
import CategorySlices from "./slices/CategorySlices";
import PropertySlices from "./slices/PropertySlices";
import VendorSlices from "./slices/VendorSlices";

const store = configureStore({
  reducer: {
    login: LoginSlices,
    user: UserSlices,
    changePassword: ChangePasswordSlices,
    category: CategorySlices,
    property: PropertySlices,
    vendor: VendorSlices,
  },
});
export default store;
