import React from "react";
import style from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={style.notFound}>
      <h1 className={style.code}>404</h1>
      <p className={style.message}>
        Oops! We couldn&apos;t find the page you&apos;re looking for.
        <br /> It might have been moved or no longer exists.
        <br /> Try returning to the <a href="/">homepage</a> or check the URL
        for any errors.
      </p>
    </div>
  );
}

export default NotFound;
