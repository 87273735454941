import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  propertyList: [],
  propertyDetails: null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
};

// Create Property
export const createPropertyActionCreator = createAsyncThunk(
  "property/create",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_PROPERTY}`;
    try {
      const { data } = await fetchUtility("post", API_URL, params.body, true, {
        successToast: true,
        errorToast: false,
      });
      return data;
    } catch (error) {
      const errors = {
        property_name: error.response?.data?.errors?.property_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

// List Properties
export const listPropertiesActionCreator = createAsyncThunk(
  "property/list",
  async () => {
    const API_URL = `${SERVER_URL.PROPERTY_LIST}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

// Retrieve Property
export const retrievePropertyActionCreator = createAsyncThunk(
  "property/retrieve",
  async (id, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.RETRIEVE_PROPERTY}${id}/`;
    try {
      const { data } = await fetchUtility("get", API_URL);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to retrieve property.");
    }
  },
);

// Update Property
export const updatePropertyActionCreator = createAsyncThunk(
  "property/update",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.UPDATE_PROPERTY}${params.body.property_id}/`;
    try {
      const { data } = await fetchUtility("put", API_URL, params.body, true, {
        // successToast: true,
        // errorToast: false,
      });
      return data;
    } catch (error) {
      const errors = {
        property_name: error.response?.data?.errors?.property_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

// Delete Property
export const deletePropertyActionCreator = createAsyncThunk(
  "property/delete",
  async (id, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.DELETE_PROPERTY}${id}/`;
    try {
      const { data } = await fetchUtility("delete", API_URL, null, true, {
        // successToast: true,
        errorToast: false,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to delete property.");
    }
  },
);

const propertySlice = createSlice({
  name: "property",
  initialState,
  extraReducers: (builder) => {
    // Create Property
    builder.addCase(createPropertyActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPropertyActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(createPropertyActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // List Properties
    builder.addCase(listPropertiesActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listPropertiesActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.propertyList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(listPropertiesActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Retrieve Property
    builder.addCase(retrievePropertyActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      retrievePropertyActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.propertyDetails = action.payload;
        state.error = "";
      },
    );
    builder.addCase(retrievePropertyActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Update Property
    builder.addCase(updatePropertyActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePropertyActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updatePropertyActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    // Delete Property
    builder.addCase(deletePropertyActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletePropertyActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deletePropertyActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default propertySlice.reducer;
