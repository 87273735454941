const ToastContent = ({ title, description }) => {
  return (
    <div>
      <h4 className="toast-title">{title}</h4>
      <p className="toast-description">{description}</p>
    </div>
  );
};

export default ToastContent;
