import "./Button.scss";

const Button = ({
  text,
  additionalClass,
  disable = false,
  clickFunction,
  loader = false,
}) => {
  return (
    <button
      className={`btn ${additionalClass}`}
      disabled={disable}
      onClick={clickFunction}
    >
      {loader ? <span className="btn-loader"></span> : text}
    </button>
  );
};
export default Button;
