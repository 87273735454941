import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import Error from "./assets/icons/error.svg";
import { logoutActionCreator } from "./redux/slices/LoginSlices";
import ToastContent from "./components/toast/ToastContent";

export const useIdle = (timeout = 3600000, onIdle) => {
  const dispatch = useDispatch();
  const loginToken = useSelector((state) => state.login.loginDetail);
  const userProfile = useSelector((state) => state.login.userProfile);
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    if (userProfile) {
      let timer;
      const resetTimer = () => {
        if (isIdle) setIsIdle(false); // Reset idle state
        clearTimeout(timer);
        timer = setTimeout(() => {
          setIsIdle(true);
          dispatch(
            logoutActionCreator({
              body: { refresh: loginToken?.refresh },
              toast: { successToast: false, errorToast: false },
            }),
          ); // Trigger logout API call
          toast.error(
            <ToastContent
              title="Error"
              description={"Your session has expired due to inactivity"}
            />,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              icon: () => <img src={"/assets/icons/error.svg"} />,
            },
          );
        }, timeout);
      };

      // Add event listeners
      window.addEventListener("mousemove", resetTimer);
      window.addEventListener("keydown", resetTimer);

      // Start the timer initially
      timer = setTimeout(() => {
        setIsIdle(true);
        dispatch(
          logoutActionCreator({ body: { refresh: loginToken?.refresh } }),
        ); // Trigger logout API call
      }, timeout);

      // Cleanup
      return () => {
        clearTimeout(timer);
        window.removeEventListener("mousemove", resetTimer);
        window.removeEventListener("keydown", resetTimer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeout, onIdle, isIdle, userProfile]);

  return isIdle;
};
