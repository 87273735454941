import { IoLockClosedSharp } from "react-icons/io5";
import style from "./AccessDenied.module.scss";

const AccessDenied = () => {
  return (
    <div className={style.denied}>
      <IoLockClosedSharp className={style.icons} />
      <h1 className={style.text}>Access Denied</h1>
      <p className={style.message}>
        You do not have permission to view this page. <br /> Please check with
        the site admin
      </p>
    </div>
  );
};

export default AccessDenied;
